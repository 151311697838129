// imports
import { lazy } from "react";
import Loadable from "../../custom-components/layout-components/Loadable";

//Assets

const ShgsDataTable = Loadable(lazy(() => import("../../views/state/shg-section/shgs-data/ShgsDataTable")));
const ShgMembersDataTable = Loadable(lazy(() => import("../../views/state/shg-section/shg-members-data/ShgMembersDataTable")));

const parent = "shg/"
const modifiedParent = "shg-members/"
const stateShgRoutes = [
  { path: parent + "shgs-data-table", element: <ShgsDataTable /> },
  { path: modifiedParent + "shg-members-table", element: <ShgMembersDataTable />, },
];

export default stateShgRoutes;
