// imports
import { lazy } from "react";
import Loadable from "../../../../custom-components/layout-components/Loadable";

const AddCreditLinkageTable = Loadable(lazy(() => import("../../../../views/block/thematic-intervention/financial-inclusion/credit-linkage/AddCreditLinkageTable")));

const parent = "thematic-intervention/financial-inclusion/"

const fiCreditLinkageRoutes = [
    { path: parent + "add-new-credit-linkage", element: <AddCreditLinkageTable />, },
];

export default fiCreditLinkageRoutes;
