// imports
import { lazy } from "react";
import Loadable from "../custom-components/layout-components/Loadable";

const MyAccountPage = Loadable(lazy(() => import("../../src/views/common-pages/my-account/MyAccount")));

const parent = ""
const otherRoutes = [
  { path: parent + "my-account", element: <MyAccountPage />, },
];

export default otherRoutes;
