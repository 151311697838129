// imports
import { lazy } from "react";
import Loadable from "../../../../custom-components/layout-components/Loadable";

const AddNewIfc = Loadable(lazy(() => import("../../../../views/block/thematic-intervention/farm-livelihoods/ifc/AddNewIfc")));

const parent = "thematic-intervention/farm-livelihoods/"

const flIfcRoutes = [
    { path: parent + "add-new-ifc", element: <AddNewIfc />, },
];

export default flIfcRoutes;
