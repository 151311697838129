// imports
import { lazy } from "react";
import Loadable from "../../../../custom-components/layout-components/Loadable";

const AddNewLA = Loadable(lazy(() => import("../../../../views/block/thematic-intervention/farm-livelihoods/la/AddNewLA")));

const parent = "thematic-intervention/farm-livelihoods/"

const flLARoutes = [
    { path: parent + "add-new-la", element: <AddNewLA />, },
];

export default flLARoutes;
