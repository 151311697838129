//const stage = "dev"; //prod/prod/dev
const stage = "prod"; //prod/prod/dev

let ip;
//check staging
if (stage == "test") {
  ip = "https://test.api.arsrlmmats.in/";
} else if (stage == "prod") {
  ip = "https://api.arsrlmmats.in/";
} else if (stage == "dev") {
  ip = "http://147.79.68.111:5001/";
}

const config = {
  basename: "/",
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  drawerWidth: 40,
  appBarHeight: 138,
  tableWidth: 300,
  stage: stage,
  Server_IP_Address: ip,
  version: "v0.9.19",
};

//its a me mario
export default config;
