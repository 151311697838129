// imports
import { lazy } from "react";
import Loadable from "../../../../custom-components/layout-components/Loadable";

const AddNewVdvkOp = Loadable(lazy(() => import("../../../../views/block/thematic-intervention/farm-livelihoods/vdvkOp/AddNewVdvkOp")));

const parent = "thematic-intervention/farm-livelihoods/"

const flVdvkOpRoutes = [
    { path: parent + "add-new-vdvk-op", element: <AddNewVdvkOp />, },
];

export default flVdvkOpRoutes;
