// imports
import { lazy } from "react";
import Loadable from "../../custom-components/layout-components/Loadable";

//Assets
const TrainingDocsTable = Loadable(lazy(() => import("../../views/state/proposals/training-docs/TrainingDocsTable")));
const TrainedMembersDetailsTable = Loadable(lazy(() => import("../../views/state/proposals/trained-members-details/TrainedMembersDetailsTable")));

//routes
const parent = "proposals/"
const stateProposalRoutes = [
  { path: parent + "training-docs", element: <TrainingDocsTable />, },
  { path: parent + "trained-member-details", element: <TrainedMembersDetailsTable />, },
];

export default stateProposalRoutes;
