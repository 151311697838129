// assets
import { IconBulb } from "@tabler/icons";

// constant
const icons = { IconBulb, };

//Menu Items
const parent = "/state/proposals";
const stateProposals = {
  type: "group",
  children: [
    {
      id: "Proposals",
      title: "Proposals",
      type: "collapse",
      icon: icons.IconBulb,

      children: [
        {
          id: "Training Docs",
          title: "Training Docs",
          type: "item",
          url: parent + "/training-docs",
        },
        {
          id: "Trained Members Details",
          title: "Trained Members Details",
          type: "item",
          url: parent + "/trained-member-details",
        },
      ],
    },
  ],
};
export default stateProposals;
