import { Avatar, Button, Fade, List, ListItemButton, ListItemIcon, ListItemText, Popper, Typography } from '@mui/material'
import { IconUser } from '@tabler/icons'
import React, { useRef, useState } from 'react'
import { useTheme } from "@mui/material/styles";
import { Box } from '@mui/system';
import { IconCaretDownFilled } from '@tabler/icons-react';
import useAuthStore from '../../../../controllers/zustand/store';
import { useNavigate } from 'react-router-dom';

const ProfileSection = () => {
  const { clearAuth } = useAuthStore();
  const theme = useTheme();
  const anchorRef = useRef(null);

  const navigate = useNavigate();
  const [listOpen, setlistOpen] = useState(false);

  const handleToggle = () => { setlistOpen(!listOpen); };

  const handleLogout = () => {
    clearAuth()
    navigate("/login");
  };

  const handleNavigateToMyAccount = () => {
    setlistOpen(false)
    navigate("/my-account")
  };
  return (
    <>
      <Avatar
        sx={{
          width: 25, height: 25,
          background: "black",
          borderRadius: 8,
          border: 1,
          marginRight: 2,
          borderColor: "white",
          color: "white",
        }}
        className="bg-zinc-900"
      >
        <IconUser stroke={1.5} size="1em" />
      </Avatar>
      <button onClick={handleToggle}
        ref={anchorRef}
      >
        <IconCaretDownFilled size="1.3em" />
      </button>
      {/* popper menu */}
      <Popper
        placement="bottom-end"
        open={listOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [{ name: "offset", options: { offset: [0, 10], } }],
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} >
            <Box sx={{ borderRadius: 1 }}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 170,
                  minWidth: 170,
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: 1,
                }}
                className='bg-slate-600'
              >
                <ListItemButton sx={{ marginX: 1, borderRadius: 1.5, ":hover": { bgcolor: "#262726" } }} >
                  <Typography
                    variant="h4"
                    sx={{ fontFamily: "Montserrat, sans-serif", color: "white" }}
                    className="m-y-auto" onClick={handleNavigateToMyAccount}
                  >
                    My Account
                  </Typography>
                </ListItemButton>
                <ListItemButton sx={{ marginX: 1, borderRadius: 1.5, ":hover": { bgcolor: "#262726" } }} onClick={handleLogout} >
                  <Typography
                    variant="h4"
                    sx={{ fontFamily: "Montserrat, sans-serif", color: "white" }}
                    className="m-y-auto"
                  >
                    Logout
                  </Typography>
                </ListItemButton>
              </List>
            </Box>
          </Fade>
        )}
      </Popper>
    </>
  )
}

export default ProfileSection