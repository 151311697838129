import { create } from 'zustand'
import Cookies from 'js-cookie';

const useAuthStore = create((set) => ({
    userName: Cookies.get('userName') || '',
    district: Cookies.get('district') || '',
    block: Cookies.get('block') || '',
    userType: Cookies.get('userType') || '',
    token: Cookies.get('token') || '',
    getUser: () => {
        const user = {
            _id: Cookies.get('_id') || '',
            block: Cookies.get('block') || '',
            district: Cookies.get('district') || '',
            userName: Cookies.get('userName') || '',
            userType: Cookies.get('userType') || '',
        };
        return user;
    },
    setAuth: (data) => {
        set({
            _id: data._id,
            userName: data.userName,
            userType: data.userType,
            district: data.district,
            block: data.block,
            token: data.token,
        });
        Cookies.set('_id', data._id);
        Cookies.set('userName', data.userName);
        Cookies.set('district', data.district);
        Cookies.set('block', data.block);
        Cookies.set('userType', data.userType);
        Cookies.set('token', data.token);
    },
    clearAuth: () => {
        Cookies.remove('_id');
        Cookies.remove('userName');
        Cookies.remove('district');
        Cookies.remove('block');
        Cookies.remove('userType');
        Cookies.remove('token');
        set({ userName: '', userType: '', token: '', district: '', block: '', _id: '' });
    },
}))

export default useAuthStore