// imports
import { lazy } from "react";
import Loadable from "../../custom-components/layout-components/Loadable";

//Assets
const VerifyShgTable = Loadable(lazy(() => import("../../views/district/shg-section/verify-new-shg/VerifyShgTable")));
const VerifiedShgDataTable = Loadable(lazy(() => import("../../views/district/shg-section/verified-shgs-data/VerifiedShgDataTable")));

const parent = "shg/"
const districtShgRoutes = [
  { path: parent + "verify-shg-table", element: <VerifyShgTable /> },
  { path: parent + "verified-shg-data-table", element: <VerifiedShgDataTable /> },
];

export default districtShgRoutes;
