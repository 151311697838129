// imports
import { lazy } from "react";
import Loadable from "../../custom-components/layout-components/Loadable";

//basic operations
const AddNewPlfTable = Loadable(lazy(() => import("../../views/block/plf-section/federation-profile/AddNewPlfTable")));
const AddNewPlfPage = Loadable(lazy(() => import("../../views/block/plf-section/federation-profile/AddNewPlfPage")));
const EditPlfPage = Loadable(lazy(() => import("../../views/block/plf-section/federation-profile/EditPlfPage")));
const VerifiedPlfDataTable = Loadable(lazy(() => import("../../views/block/plf-section/verified-plfs-data/VerifiedPlfDataTable")));

//mapping
const FederationMappingTable = Loadable(lazy(() => import("../../views/block/plf-section/federation-mapping/FederationMappingTable")));
const ShgstoPlfMapping = Loadable(lazy(() => import("../../views/block/plf-section/federation-mapping/shgs-to-plf-mapping/ShgstoPlfMapping")));
const VillagesToPlfMapping = Loadable(lazy(() => import("../../views/block/plf-section/federation-mapping/villages-to-plf-mapping/VillagesToPlfMapping")));
const MembersToPlfMapping = Loadable(lazy(() => import("../../views/block/plf-section/federation-mapping/members-to-plf-mapping/MembersToPlfMapping")));

const parent = "plf/"
const blockPlfRoutes = [
  { path: parent + "add-new-plf-table", element: <AddNewPlfTable />, },
  { path: parent + "add-new-plf", element: <AddNewPlfPage />, },
  { path: parent + "edit-plf/:_id", element: <EditPlfPage />, },
  { path: parent + "plf-data-table", element: <VerifiedPlfDataTable />, },

  //mapping routes
  { path: parent + "federation-mapping", element: <FederationMappingTable />, },
  { path: parent + "shgs-to-plf-mapping/:_id", element: <ShgstoPlfMapping />, },
  { path: parent + "villages-to-plf-mapping/:_id", element: <VillagesToPlfMapping />, },
  { path: parent + "members-to-plf-mapping/:_id", element: <MembersToPlfMapping />, },

];

export default blockPlfRoutes;
