// imports
import { lazy } from "react";
import Loadable from "../../custom-components/layout-components/Loadable";

//Assets
const CadreProfileTable = Loadable(lazy(() => import("../../views/block/cadre-section/cadre-profile/CadreProfileTable")));
const AddNewCadrePage = Loadable(lazy(() => import("../../views/block/cadre-section/cadre-profile/AddNewCadrePage")));
const EngagedCadresTable = Loadable(lazy(() => import("../../views/block/cadre-section/engaged-cadre-details/EngagedCadresTable")));
const CadreEngagementPage = Loadable(lazy(() => import("../../views/block/cadre-section/cadre-profile/CadreEngagementPage")));

const parent = "cadre/"
const blockCadreRoutes = [
  { path: parent + "cadre-profile-details", element: <CadreProfileTable />, },
  { path: parent + "add-new-cadre", element: <AddNewCadrePage />, },
  { path: parent + "cadre-engagement/:_id", element: <CadreEngagementPage />, },
  { path: parent + "engaged-cadre-details", element: <EngagedCadresTable />, },
];

export default blockCadreRoutes;
