// assets
import {
  IconMap2,
  IconPresentationAnalytics,
  IconIndentIncrease,
  IconCubeUnfolded
} from "@tabler/icons";

// constant
const icons = { IconMap2, IconPresentationAnalytics, IconCubeUnfolded };

//Menu Items
const parent = "/state/main-thematics";
const stateThematics = {
  id: "sample-docs-roadmap",
  type: "group",
  children: [
    {
      id: "Add New Thematic",
      title: "Add New Thematic",
      type: "item",
      url: parent + "/add-new-thematic",
      icon: icons.IconCubeUnfolded,
      breadcrumbs: false,
    },
  ],
};

export default stateThematics;
