// assets
import {
    IconMap2,
    IconPresentationAnalytics,
    IconIndentIncrease,
} from "@tabler/icons";

// constant
const icons = { IconMap2, IconPresentationAnalytics, IconIndentIncrease };

const parent = "/district/trainings";
const districtTrainings = {
    type: "group",
    children: [
        {
            id: "Trainings",
            title: "Trainings",
            type: "collapse",
            icon: icons.IconPresentationAnalytics,

            children: [
                {
                    id: "Training Details",
                    title: "Training Details",
                    type: "item",
                    url: parent + "/training-details",
                },
            ],
        },
    ],

};

export default districtTrainings;
