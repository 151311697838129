// assets
import { IconArchive } from "@tabler/icons";

// constant
const icons = { IconArchive, };

const parent = "/block/plf";
const blockPlf = {
  type: "group",
  children: [
    {
      id: "Primary Level Federation",
      title: "Primary Level Federation",
      type: "collapse",
      icon: icons.IconArchive,

      children: [
        {
          id: "Federation Profile",
          title: "Federation Profile",
          type: "item",
          url: parent + "/add-new-plf-table",
        },
        {
          id: "Federation Mapping",
          title: "Federation Mapping",
          type: "item",
          url: parent + "/federation-mapping",
        },
        {
          id: "Verified PLFs Data",
          title: "Verified PLFs Data",
          type: "item",
          url: parent + "/plf-data-table",
        },
      ],
    },
  ],
};

export default blockPlf;
