import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Drawer, Grid, useMediaQuery } from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import { BrowserView, MobileView } from "react-device-detect";

// project imports
import MenuList from "./MenuList";
import config from "../../../config";
// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ toggleDrawer, window, leftDrawerOpened }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Drawer
        anchor="left"
        open={leftDrawerOpened}
        variant="persistent"
      >
        <Box
          sx={{ minHeight: config.appBarHeight }}
          className="bg-cyan-200"
        ></Box>
        <MenuList />
      </Drawer>

    </>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default Sidebar;
