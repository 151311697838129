// imports
import { lazy } from "react";
import Loadable from "../../../../custom-components/layout-components/Loadable";

const AddInsuranceTable = Loadable(lazy(() => import("../../../../views/block/thematic-intervention/financial-inclusion/insurance/AddInsuranceTable")));

const parent = "thematic-intervention/financial-inclusion/"

const fiInsuranceRoutes = [
    { path: parent + "add-new-insurance-data", element: <AddInsuranceTable />, },
];

export default fiInsuranceRoutes;
