// imports
import { lazy } from "react";
import Loadable from "../../custom-components/layout-components/Loadable";

//Assets
const VerifyShgFundsTable = Loadable(lazy(() => import("../../views/district/funds-tracking/verify-shg-funds/VerifyShgFundsTable")));
const VerifyPlfFundsTable = Loadable(lazy(() => import("../../views/district/funds-tracking/verify-plf-funds/VerifyPlfFundsTable")));
const VerifyClfFundsTable = Loadable(lazy(() => import("../../views/district/funds-tracking/verify-clf-funds/VerifyClfFundsTable")));
const VerifyDisbursementFundsTable = Loadable(lazy(() => import("../../views/district/funds-tracking/verify-disbursement-funds/VerifyDisbursementFundsTable")));

//routes
const parent = "funds-tracking/"
const districtFundsTrackingRoutes = [
  { path: parent + "verify-shg-funds", element: <VerifyShgFundsTable />, },
  { path: parent + "verify-plf-funds", element: <VerifyPlfFundsTable />, },
  { path: parent + "verify-clf-funds", element: <VerifyClfFundsTable />, },
  { path: parent + "verify-disbursed-funds", element: <VerifyDisbursementFundsTable />, },
];

export default districtFundsTrackingRoutes;
