import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, ButtonBase, Divider, Toolbar, Typography } from "@mui/material";

// project imports
import NotificationSection from "../UserDetails/NotificationSection";

// assets
import { Stack } from "@mui/system";
import arsrlmLogo from "../../../assets/arsrlmLogo.png";
import nrlmLogo from "../../../assets/nrlmLogo.png";
// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();

  return (
    <Toolbar className="bg-cyan-800 " sx={{ padding: 1 }}>
      < Stack direction={"row"} sx={{ display: "flex", padding: 0, alignItems: "center", flexGrow: 1 }} >
        <Box>
          <img src={arsrlmLogo} alt="Arsrlm Logo" height={100} width={100} />
        </Box>
        <Box component="span" className="ml-10">
          <Typography
            variant="h2"
            sx={{ fontFamily: "Merriweather, serif", color: "white" }}
          >
            ArSRLM MATS
          </Typography>
          <Typography
            variant="h3"
            sx={{ fontFamily: "Montserrat, sans-serif", color: "white" }}
            className="mt-1"
          >
            Arunachal State Rural Livelihoods Mission
          </Typography>

          <Typography
            variant="h4"
            sx={{
              fontFamily: "Montserrat, sans-serif",
              color: "white",
            }}
          >
            Itanagar
          </Typography>
        </Box>
      </Stack >
      <img src={nrlmLogo} alt="nrlm Logo" height={100} width={100} />
    </Toolbar>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
