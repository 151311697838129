// imports
import { lazy } from "react";
import Loadable from "../../custom-components/layout-components/Loadable";

//Assets
const VerifyShgMemberTable = Loadable(lazy(() => import("../../views/district/shg-section/verify-new-member/VerifyShgMemberTable")));
const VerifiedShgMembersTable = Loadable(lazy(() => import("../../views/district/shg-section/verified-shg-members/VerifiedShgMembersTable")));

const parent = "shg-members/"
const districtShgMemberRoutes = [
  { path: parent + "verify-shg-member-table", element: <VerifyShgMemberTable /> },
  { path: parent + "verified-shg-members-table", element: <VerifiedShgMembersTable /> },
];

export default districtShgMemberRoutes;
