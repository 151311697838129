// imports
import { lazy } from "react";
import Loadable from "../../custom-components/layout-components/Loadable";

//Assets
const VerifyClfTable = Loadable(lazy(() => import("../../views/district/clf-section/verify-new-clf/VerifyClfTable")));
const VerifiedClfDataTable = Loadable(lazy(() => import("../../views/district/clf-section/verified-clfs-data/VerifiedClfDataTable")));

const parent = "clf/"
const districtClfRoutes = [
  { path: parent + "verify-clf-table", element: <VerifyClfTable /> },
  { path: parent + "clf-data-table", element: <VerifiedClfDataTable />, },
];

export default districtClfRoutes;
