// imports
import { lazy } from "react";
import Loadable from "../../../../custom-components/layout-components/Loadable";

const AddNewMPUData = Loadable(lazy(() => import("../../../../views/block/thematic-intervention/farm-livelihoods/mother-piggery-unit/AddNewMPUData")));

const parent = "thematic-intervention/farm-livelihoods/"

const flMpRoutes = [
    { path: parent + "add-new-mpu-data", element: <AddNewMPUData />, },
];

export default flMpRoutes;
