import Loadable from "../../custom-components/layout-components/Loadable";
import { lazy } from "react";

//state routes
import stateShgRoutes from "./ShgRoutes";
import statePlfRoutes from "./PlfRoutes";
import stateClfRoutes from "./ClfRoutes";
import userRoutes from "./UsersRoutes";
import stateThematicRoutes from "./ThematicRoutes";
import logsRoutes from "./LogsRoutes";
import LocationMasterRoutes from "./LocationMasterRoutes";
import stateTrainingsRoutes from "./trainingRoutes";
import stateProposalRoutes from "./ProposalRoutes";
import stateCadreRoutes from "./CadreRoutes";
import stateFundsTrackingRoutes from "./FundsTrackingRoutes";
import mainThematicRoutes from "./MainThematicRoutes";

const StateDashboard = Loadable(
    lazy(() => import("../../views/state/dashboard/MainDashBoard"))
);

const stateRoutesList = [
    ...stateShgRoutes,
    ...statePlfRoutes,
    ...stateClfRoutes,
    ...userRoutes,
    ...stateThematicRoutes,
    ...logsRoutes,
    ...LocationMasterRoutes,
    ...stateTrainingsRoutes,
    ...stateProposalRoutes,
    ...stateFundsTrackingRoutes,
    ...stateCadreRoutes,
    ...mainThematicRoutes,
    {
        path: "dashboard",
        element: <StateDashboard />,
    },
];

export default stateRoutesList