import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { Toaster, toast } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";

//projectImports
import themes from "./themes";
import Routes from "./routes/MainRoutes";

// style + assets
import "./assets/scss/style.scss";

const App = () => {
  const customization = {
    isOpen: [],
    defaultId: "default",
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    opened: true,
  };

  return (
    <>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={themes(customization)}>
            <CssBaseline />
            <Routes />
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
      <Toaster position="top-right" />
    </>
  );
}

export default App;
