// imports
import { lazy } from "react";
import Loadable from "../../../../custom-components/layout-components/Loadable";

const AddNewPG = Loadable(lazy(() => import("../../../../views/block/thematic-intervention/farm-livelihoods/pg/AddNewPG")));

const parent = "thematic-intervention/farm-livelihoods/"

const flPgRoutes = [
    { path: parent + "add-new-pg", element: <AddNewPG />, },
];

export default flPgRoutes;
