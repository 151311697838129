// imports
import { lazy } from "react";
import Loadable from "../../../../custom-components/layout-components/Loadable";

const AddNewAep = Loadable(lazy(() => import("../../../../views/block/thematic-intervention/farm-livelihoods/aep/AddNewAep")));

const parent = "thematic-intervention/farm-livelihoods/"

const flAepRoutes = [
    { path: parent + "add-new-aep", element: <AddNewAep />, },
];

export default flAepRoutes;
