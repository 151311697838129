//block items
import blockDashboard from "./block/dashboard";
import blockShg from "./block/shg";
import blockPlf from "./block/plf";
import blockClf from "./block/clf";
import blockFundsTracking from "./block/fundsTracking";
import blockProposals from "./block/proposals";
import blockThematicIntervention from "./block/thematicIntervention";
import blockCadres from "./block/cadre";
import ticketing from "./block/ticketing";

//district items
import districtDashboard from "./district/dashboard";
import districtShg from "./district/shg";
import districtPlf from "./district/plf";
import districtClf from "./district/clf";
import districtFundsTracking from "./district/fundsTracking";
import districtCadres from "./district/cadre";
import districtThematicIntervention from "./district/thematicIntervention";
import districtTrainings from "./district/trainings";
import districtProposals from "./district/proposals";

//state items
import stateDashboard from "./state/dashboard";
import stateShg from "./state/shg";
import statePlf from "./state/plf";
import stateClf from "./state/clf";
import stateThematics from "./state/thematics";
import logs from "./state/logs";
import locationMaster from "./state/locationMaster";
import stateTrainings from "./state/trainings";
import stateThematicIntervention from "./state/thematicIntervention";
import stateFundsTracking from "./state/fundsTracking";
import stateProposals from "./state/proposals";
import stateCadres from "./state/cadre";
import stateUsers from "./state/users";

// ==============================|| MENU ITEMS ||============================== //
const menuItems = {
  stateItems: [
    stateDashboard,
    stateShg,
    statePlf,
    stateClf,
    stateFundsTracking,
    stateProposals,
    stateCadres,
    stateTrainings,
    stateThematics,
    stateThematicIntervention,
    locationMaster,
    stateUsers,
    logs,
  ],

  districtItems: [
    districtDashboard,
    districtShg,
    districtPlf,
    districtClf,
    districtFundsTracking,
    districtCadres,
    districtProposals,
    districtTrainings,
    districtThematicIntervention,
  ],

  blockItems: [
    blockDashboard,
    blockShg,
    blockPlf,
    blockClf,
    blockFundsTracking,
    blockCadres,
    blockProposals,
    blockThematicIntervention,
    // ticketing,
  ],
};

export default menuItems;
