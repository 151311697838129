import { Avatar, Button, Fade, List, ListItemButton, ListItemIcon, ListItemText, Popper, Typography } from '@mui/material'
import { IconBell, IconUser } from '@tabler/icons'
import React, { useRef, useState } from 'react'
import { useTheme } from "@mui/material/styles";
import { Box } from '@mui/system';
import { IconCaretDownFilled } from '@tabler/icons-react';
import useAuthStore from '../../../../controllers/zustand/store';
import { useNavigate } from 'react-router-dom';

const NotificationSection = () => {
    const { clearAuth } = useAuthStore();
    const theme = useTheme();
    const anchorRef = useRef(null);

    const navigate = useNavigate();
    const [listOpen, setlistOpen] = useState(false);

    const handleToggle = () => { setlistOpen(!listOpen); };

    const handleLogout = () => {
        clearAuth()
        navigate("/login");
    };

    const handleNavigateToMyAccount = () => {
        setlistOpen(false)
        navigate("/my-account")
    };
    return (
        <>
            <Avatar
                sx={{
                    width: 25, height: 25,
                    background: "black",
                    borderRadius: 8,
                    border: 1,
                    marginRight: 2,
                    borderColor: "white",
                    color: "white",
                }}
                className="bg-zinc-900"
            >
                <IconBell stroke={1.5} size="1em" />
            </Avatar>
        </>
    )
}

export default NotificationSection