// assets
import { IconUsers } from "@tabler/icons";

// constant
const icons = {
  IconUsers,
};

//Menu Items
const parent = "/state/users";
const stateUsers = {
  id: "users",
  type: "group",
  children: [
    {
      id: "Users",
      title: "Users",
      type: "collapse",
      icon: icons.IconUsers,

      children: [
        {
          id: "User Details",
          title: "User Details",
          type: "item",
          url: parent + "/user-details-table",
        },
        {
          id: "Add New User",
          title: "Add New User",
          type: "item",
          url: parent + "/add-new-user",
        },
        {
          id: "Users Password Update",
          title: "Users Password Update",
          type: "item",
          url: parent + "/users-password-info",
        },
      ],
    },
  ],
};

export default stateUsers;
