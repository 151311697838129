// imports
import { lazy } from "react";
import Loadable from "../../custom-components/layout-components/Loadable";

const AddNewThematic = Loadable(lazy(() => import("../../views/state/add-new-thematics-section/AddNewThematic")));

const parent = "main-thematics/"
const stateThematicRoutes = [
  { path: parent + "add-new-thematic", element: <AddNewThematic />, },
];

export default stateThematicRoutes;
