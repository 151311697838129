import Loadable from "../../custom-components/layout-components/Loadable";
import { lazy } from "react";

//district Routes
import districtShgRoutes from "./ShgRoutes";
import districtShgMemberRoutes from "./ShgMemberRoutes";
import districtPlfRoutes from "./PlfRoutes";
import districtClfRoutes from "./ClfRoutes";
import districtCadreRoutes from "./CadreRoutes";
import districtFundsTrackingRoutes from "./FundsTrackingRoutes";
import districtTrainingRoutes from "./TrainingRoutes";
import districtProposalRoutes from "./ProposalRoutes";

import mainThematicRoutes from "./MainThematicRoutes";

const DistrictDashboard = Loadable(lazy(() => import("../../views/district/dashboard/MainDashBoard")));

const districtRoutesList = [
    ...districtShgRoutes,
    ...districtShgMemberRoutes,
    ...districtPlfRoutes,
    ...districtClfRoutes,
    ...districtProposalRoutes,
    ...districtCadreRoutes,
    ...districtFundsTrackingRoutes,
    ...districtTrainingRoutes,

    //thematic Routes
    ...mainThematicRoutes,

    { path: "dashboard", element: <DistrictDashboard />, },
];

export default districtRoutesList