// assets
import {
    IconMap2,
    IconPresentationAnalytics,
    IconIndentIncrease,
} from "@tabler/icons";

// constant
const icons = { IconMap2, IconPresentationAnalytics, IconIndentIncrease };

//Menu Items
const parent = "/state/trainings";
const stateTrainings = {
    type: "group",
    children: [
        {
            id: "Training Details",
            title: "Training Details",
            type: "item",
            url: parent + "/trainings-details-table",
            icon: icons.IconPresentationAnalytics,
        },
    ],

};

export default stateTrainings;
