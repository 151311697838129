// imports
import { lazy } from "react";
import Loadable from "../../custom-components/layout-components/Loadable";

//shg members
const AddNewShgMemberPage = Loadable(lazy(() => import("../../views/block/shg-section/add-member-profile/AddNewShgMemberPage")));
const ShgMembersTable = Loadable(lazy(() => import("../../views/block/shg-section/shg-members-data/ShgMembersTable")));
const EditShgMemberPage = Loadable(lazy(() => import("../../views/block/shg-section/shg-members-data/EditShgMemberPage")));

const parent = "shg-members/"
const blockShgMembersRoutes = [
    { path: parent + "add-new-shg-member", element: <AddNewShgMemberPage />, },
    { path: parent + "shg-members-table", element: <ShgMembersTable />, },
    { path: parent + "edit-shg-member/:_id", element: <EditShgMemberPage />, },
];

export default blockShgMembersRoutes;
