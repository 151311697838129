// assets
import { IconReportSearch } from "@tabler/icons";

// constant
const icons = {
  IconReportSearch,
};

//Menu Items
const parent = "/district/funds-tracking";
const districtFundsTracking = {
  type: "group",
  children: [
    {
      id: "Funds Tracking",
      title: "Funds Tracking",
      type: "collapse",
      icon: icons.IconReportSearch,

      children: [
        {
          id: "Verify SHG Funds",
          title: "Verify SHG Funds",
          type: "item",
          url: parent + "/verify-shg-funds",
        },
        {
          id: "Verify PLF Funds",
          title: "Verify PLF Funds",
          type: "item",
          url: parent + "/verify-plf-funds",
        },
        {
          id: "Verify CLF Funds",
          title: "Verify CLF Funds",
          type: "item",
          url: parent + "/verify-clf-funds",
        },
        {
          id: "Verify Disbursed Funds",
          title: "Verify Disbursed Funds",
          type: "item",
          url: parent + "/verify-disbursed-funds",
        }
      ],
    },
  ],
};

export default districtFundsTracking;
