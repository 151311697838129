// imports
import { lazy } from "react";
import Loadable from "../../../../custom-components/layout-components/Loadable";

const AddNewVet = Loadable(lazy(() => import("../../../../views/block/thematic-intervention/farm-livelihoods/vet/AddNewVet")));

const parent = "thematic-intervention/farm-livelihoods/"

const flVetRoutes = [
    { path: parent + "add-new-vet", element: <AddNewVet />, },
];

export default flVetRoutes;
