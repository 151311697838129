// imports
import { lazy } from "react";
import Loadable from "../../custom-components/layout-components/Loadable";

//Assets
const UserDetailsTable = Loadable(lazy(() => import("../../views/state/users-section/user-details/UserDetailsTable")));
const AddNewUserPage = Loadable(lazy(() => import("../../views/state/users-section/add-new-user/AddNewUserPage")));
const UsersPasswordInfoTable = Loadable(lazy(() => import("../../views/state/users-section/users-password-info/UsersPasswordInfoTable")));

const parent = "users/"
const userRoutes = [
  { path: parent + "user-details-table", element: <UserDetailsTable />, },
  { path: parent + "add-new-user", element: <AddNewUserPage />, },
  { path: parent + "users-password-info", element: <UsersPasswordInfoTable />, },
];

export default userRoutes;
