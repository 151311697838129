// assets
import { IconFileText } from "@tabler/icons";

// constant
const icons = {
  IconFileText,
};

//Menu Items
const parent = "/district/shg";
const modifiedParent = "/district/shg-members"

const districtShg = {
  type: "group",
  children: [
    {
      id: "Self Help Group",
      title: "Self Help Group",
      type: "collapse",
      icon: icons.IconFileText,

      children: [
        {
          id: "Verify New SHG",
          title: "Verify New SHG",
          type: "item",
          url: parent + "/verify-shg-table",
        },
        {
          id: "Verify New Member Profile",
          title: "Verify New Member Profile",
          type: "item",
          url: modifiedParent + "/verify-shg-member-table",
        },
        {
          id: "Verified SHGs Data",
          title: "Verified SHGs Data",
          type: "item",
          url: parent + "/verified-shg-data-table",
        },
        {
          id: "Verified SHG Members",
          title: "Verified SHG Members",
          type: "item",
          url: modifiedParent + "/verified-shg-members-table",
        },
      ],
    },
  ],
};

export default districtShg;
