// imports
import { lazy } from "react";
import Loadable from "../../../../custom-components/layout-components/Loadable";

const AddNewVdvk = Loadable(lazy(() => import("../../../../views/block/thematic-intervention/farm-livelihoods/vdvk/AddNewVdvk")));

const parent = "thematic-intervention/farm-livelihoods/"

const flVdvkRoutes = [
    { path: parent + "add-new-vdvk", element: <AddNewVdvk />, },
];

export default flVdvkRoutes;
