import Loadable from "../../custom-components/layout-components/Loadable";
import { lazy } from "react";

//project  Imports
import blockShgRoutes from "./ShgRoutes";
import blockShgMembersRoutes from "./ShgMemberRoutes";
import blockPlfRoutes from "./PlfRoutes";
import blockClfRoutes from "./ClfRoutes";
import blockProposalRoutes from "./ProposalRoutes";
import blockCadreRoutes from "./CadreRoutes";
import blockFundsTrackingRoutes from "./FundsTrackingRoutes";

import mainThematicRoutes from "./MainThematicRoutes";
//thematics: farm livelihood
import flPgRoutes from "./thematicRoutes/FlRoutes/PgRoutes";
import flVdvkRoutes from "./thematicRoutes/FlRoutes/VdvkRoutes";
import flVdvkOpRoutes from "./thematicRoutes/FlRoutes/VdvkOpRoutes";
import flMpRoutes from "./thematicRoutes/FlRoutes/MpRoutes";
import flCvgRoutes from "./thematicRoutes/FlRoutes/CvgRoutes";
import flAepRoutes from "./thematicRoutes/FlRoutes/AepRoutes";
import flChcRoutes from "./thematicRoutes/FlRoutes/ChcRoutes";
import flAnkyAnbyRoutes from "./thematicRoutes/FlRoutes/AnkyAnbyRoutes";
import flLARoutes from "./thematicRoutes/FlRoutes/LaRoutes";
import flIfcRoutes from "./thematicRoutes/FlRoutes/IfcRoutes";
import flVetRoutes from "./thematicRoutes/FlRoutes/VetRoutes";

//thematics: financial inclusion
import fiCreditLinkageRoutes from "./thematicRoutes/FiRoutes/ClRoutes";
import fiInsuranceRoutes from "./thematicRoutes/FiRoutes/InsRoutes";

const BlockDashboard = Loadable(lazy(() => import("../../views/block/dashboard/MainDashBoard")));

const blockRoutesList = [
    ...blockShgRoutes,
    ...blockShgMembersRoutes,
    ...blockPlfRoutes,
    ...blockClfRoutes,
    ...blockProposalRoutes,
    ...blockCadreRoutes,
    ...blockFundsTrackingRoutes,

    //thematic Routes
    ...mainThematicRoutes,
    ...fiCreditLinkageRoutes,
    ...fiInsuranceRoutes,
    ...flPgRoutes,
    ...flVdvkRoutes,
    ...flVdvkOpRoutes,
    ...flMpRoutes,
    ...flCvgRoutes,
    ...flAepRoutes,
    ...flChcRoutes,
    ...flAnkyAnbyRoutes,
    ...flLARoutes,
    ...flIfcRoutes,
    ...flVetRoutes,

    { path: "dashboard", element: <BlockDashboard />, },
];

export default blockRoutesList