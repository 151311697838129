// imports
import { lazy } from "react";
import Loadable from "../../custom-components/layout-components/Loadable";

//Assets
const FarmLivelihood = Loadable(lazy(() => import("../../views/state/thematic-intervention/farm-livelihoods/Index")));
const FinancialInclusion = Loadable(lazy(() => import("../../views/state/thematic-intervention/financial-inclusion/Index")));

const parent = "thematic-intervention/"
const mainThematicRoutes = [
  { path: parent + "farm-livelihoods", element: <FarmLivelihood />, },
  { path: parent + "financialInclusion", element: <FinancialInclusion />, }
];

export default mainThematicRoutes;
