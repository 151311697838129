// imports
import { lazy } from "react";
import Loadable from "../../custom-components/layout-components/Loadable";

//Assets
const CadreProfileTable = Loadable(lazy(() => import("../../views/district/cadre-section/cadre-profile/CadreProfileTable")));
const EngagedCadresTable = Loadable(lazy(() => import("../../views/district/cadre-section/engaged-cadre-details/EngagedCadresTable")));

const parent = "cadre/"
const districtCadreRoutes = [
  { path: parent + "cadre-profile-details", element: <CadreProfileTable />, },
  { path: parent + "engaged-cadre-details", element: <EngagedCadresTable />, },
];

export default districtCadreRoutes;
