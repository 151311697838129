// imports
import { lazy } from "react";
import Loadable from "../../custom-components/layout-components/Loadable";

//Assets
const AddLocationTable = Loadable(
  lazy(() => import("../../views/state/location-master/AddLocationTable"))
);

const parent = "clf/"
const LocationMasterRoutes = [
  {
    path: "location-master",
    element: <AddLocationTable />,
  },
];

export default LocationMasterRoutes;
