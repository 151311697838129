// assets
import { IconFileText } from "@tabler/icons";

// constant
const icons = {
  IconFileText,
};

//Menu Items
const parent = "/state/shg";
const modifiedParent = "/state/shg-members"
const stateShg = {
  type: "group",
  children: [
    {
      id: "Self Help Group",
      title: "Self Help Group",
      type: "collapse",
      icon: icons.IconFileText,

      children: [
        {
          id: "SHGs Data",
          title: "SHGs Data",
          type: "item",
          url: parent + "/shgs-data-table",
        },
        {
          id: "SHG Members Data",
          title: "SHG Members Data",
          type: "item",
          url: modifiedParent + "/shg-members-table",
        },
      ],
    },
  ],
};

export default stateShg;
