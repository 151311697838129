// assets
import { IconMap2 } from "@tabler/icons";

// constant
const icons = {
  IconMap2,
};

//Menu Items
const parent = "/state";
const locationMaster = {
  id: "Location",
  type: "group",
  children: [
    {
      id: "Location",
      title: "Location Master",
      type: "item",
      url: parent + "/location-master",
      icon: icons.IconMap2,
    },
  ],
};

export default locationMaster;
