// assets
import { IconFileText } from "@tabler/icons";

// constant
const icons = { IconFileText, };

const parent = "/block/shg";
const modifiedParent = "/block/shg-members"

const blockShg = {
  type: "group",
  children: [
    {
      id: "Self Help Group",
      title: "Self Help Group",
      type: "collapse",
      icon: icons.IconFileText,

      children: [
        {
          id: "Add New SHG",
          title: "Add New SHG",
          type: "item",
          url: parent + "/add-new-shg-table",
        },
        {
          id: "Add Member Profile",
          title: "Add Member Profile",
          type: "item",
          url: modifiedParent + "/add-new-shg-member",
        },
        {
          id: "Verified SHGs Data",
          title: "Verified SHGs Data",
          type: "item",
          url: parent + "/verified-shg-data-table",
        },
        {
          id: "SHG Members Data",
          title: "SHG Members Data",
          type: "item",
          url: modifiedParent + "/shg-members-table",
        },
      ],
    },
  ],
};

export default blockShg;
