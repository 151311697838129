// imports
import { lazy } from "react";
import Loadable from "../../custom-components/layout-components/Loadable";

const ClfDataTable = Loadable(lazy(() => import("../../views/state/clf-section/clf-data/ClfDataTable")));
const ClfMappingDetailsTable = Loadable(lazy(() => import("../../views/state/clf-section/clf-mapping-details/ClfMappingDetailsTable")));

const parent = "clf/"
const stateClfRoutes = [
  { path: parent + "clf-data-table", element: <ClfDataTable />, },
  { path: parent + "clf-mapping-details-table", element: <ClfMappingDetailsTable />, },
];

export default stateClfRoutes;
