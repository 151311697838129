// assets
import { IconDashboard } from "@tabler/icons";

// constant
const icons = { IconDashboard };

//Menu Items
const parent = "/block";
const blockDashboard = {
  id: "blockDashboard",
  type: "group",
  children: [
    {
      id: "Dashboard",
      title: "Dashboard",
      type: "item",
      url: parent + "/dashboard",
      icon: icons.IconDashboard,
    },
  ],
};

export default blockDashboard;
