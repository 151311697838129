import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, ButtonBase, Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { IconTemplate, IconTemplateOff } from "@tabler/icons";
import ProfileSection from "./ProfileSection";

//project imports
import useAuthStore from "../../../controllers/zustand/store";
import NotificationSection from "./NotificationSection";

const UserDetails = ({
  handleLeftDrawerToggle,
  leftDrawerOpened,
  toggleDrawer,
}) => {
  const theme = useTheme();
  const { userName, userType, block, district } = useAuthStore();

  return (
    <>
      <Box sx={{ padding: 1 }} className="bg-zinc-900">
        <Stack direction="row" sx={{ justifyContent: "space-between" }}>
          <Stack direction="row" spacing={4} >
            {leftDrawerOpened ? (
              <button onClick={toggleDrawer}>
                <IconTemplateOff color="white" />
              </button>
            ) : (
              <button onClick={toggleDrawer}>
                <IconTemplate color="white" />
              </button>
            )}
            <Stack direction="row" spacing={1} ml={3}>
              <Typography
                variant="h4"
                sx={{ fontFamily: "Montserrat, sans-serif", color: "white" }}
                className="m-y-auto"
              >
                USER NAME:
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontFamily: "Montserrat, sans-serif", color: "white" }}
                className="m-y-auto"
              >
                {userName}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography
                variant="h4"
                sx={{ fontFamily: "Montserrat, sans-serif", color: "white" }}
                className="m-y-auto"
              >
                USER TYPE:
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontFamily: "Montserrat, sans-serif", color: "white" }}
                className="m-y-auto"
              >
                {userType}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} sx={{ visibility: (userType == "block" || userType == "district") ? "visible" : "hidden" }}>
              <Typography
                variant="h4"
                sx={{ fontFamily: "Montserrat, sans-serif", color: "white" }}
                className="m-y-auto"
              >
                DISTRICT:
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontFamily: "Montserrat, sans-serif", color: "white" }}
                className="m-y-auto"
              >
                {district}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} sx={{ visibility: (userType == "block") ? "visible" : "hidden" }}>
              <Typography
                variant="h4"
                sx={{ fontFamily: "Montserrat, sans-serif", color: "white" }}
                className="m-y-auto"
              >
                BLOCK:
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontFamily: "Montserrat, sans-serif", color: "white" }}
                className="m-y-auto"
              >
                {block}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" mr={1}>
            {/* <NotificationSection /> */}
            <NotificationSection />
            <ProfileSection />
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default UserDetails;
