import React, { useState, useEffect } from "react";
import {
  IconPointFilled,
  IconPhone,
  IconMail,
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandTwitter,
} from "@tabler/icons-react";

import config from "../../config";

function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  return (
    <>
      <div className="text-center py-2 bg-gray-100 ">
        <p className="text-gray-900">
          <div>
            *This site is best viewed using Google Chrome, Firefox or Microsoft
            Edge v110.2 and above versions at 1920x1080
          </div>
          &copy; {currentYear} Copyright by Starentech. All rights reserved. {config.version}
        </p>
      </div>
    </>
  );
}

export default Footer;
