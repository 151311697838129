// assets
import { IconUsers, IconPresentationAnalytics, IconIndentIncrease } from "@tabler/icons";

// constant
const icons = {
  IconUsers,
  IconPresentationAnalytics,
  IconIndentIncrease
};

//Menu Items
const parent = "/block/thematic-intervention";
const blockThematicIntervention = {
  id: "thematicIntervention",
  type: "group",
  children: [
    {
      id: "Thematic Intervention",
      title: "Thematic Intervention",
      type: "collapse",
      icon: icons.IconIndentIncrease,
      children: [
        {
          id: "Farm Livelihoods",
          title: "Farm Livelihoods",
          type: "item",
          url: parent + "/farm-livelihoods",
        },
        {
          id: "Financial Inclusion",
          title: "Financial Inclusion",
          type: "item",
          url: parent + "/financialInclusion",
        },
      ],
    },
  ],
};

export default blockThematicIntervention;
