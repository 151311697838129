// imports
import { lazy } from "react";
import Loadable from "../../custom-components/layout-components/Loadable";

//Assets
const TrainingsDetailsTable = Loadable(lazy(() => import("../../views/state/trainings/TrainingDetailsTable")));

const parent = "trainings/"
const statetrainingRoutes = [
    { path: parent + "trainings-details-table", element: <TrainingsDetailsTable />, },
];

export default statetrainingRoutes;
