// assets
import { IconCode } from "@tabler/icons";

//Menu Items
const parent = "/state/logs";
const logs = {
  id: "logs",
  type: "group",
  children: [
    {
      id: "Logs",
      title: "Logs",
      type: "collapse",
      icon: IconCode,

      children: [
        {
          id: "Activity Logs",
          title: "Activity Logs",
          type: "item",
          url: parent + "/activity-logs",
        },
        {
          id: "Error Logs",
          title: "Error Logs",
          type: "item",
          url: parent + "/error-logs",
        },
      ],
    },
  ],
};

export default logs;
