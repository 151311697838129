// assets
import { IconBulb } from "@tabler/icons";

// import trainingController from "../controllers/block/trainingController";

// constant
const icons = {
  IconBulb,
};

//Menu Items
const parent = "/block/proposals";
const blockProposals = {
  type: "group",
  children: [
    {
      id: "Proposals",
      title: "Proposals",
      type: "collapse",
      icon: icons.IconBulb,

      children: [
        {
          id: "Propose Training",
          title: "Propose Training",
          type: "item",
          url: parent + "/propose-training",
        },
        {
          id: "Training Docs",
          title: "Training Docs",
          type: "item",
          url: parent + "/training-docs",
        },
        {
          id: "Trained Members Details",
          title: "Trained Members Details",
          type: "item",
          url: parent + "/trained-member-details",
        },
      ],
    },
  ],
};
export default blockProposals;
