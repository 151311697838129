// assets
import { IconCertificate } from "@tabler/icons";

// constant
const icons = {
  IconCertificate,
};

//Menu Items
const parent = "/district/cadre";
const districtCadres = {
  type: "group",
  children: [
    {
      id: "Cadre",
      title: "Cadre",
      type: "collapse",
      icon: icons.IconCertificate,
      children: [
        {
          id: "Cadre Profile",
          title: "Cadre Profile",
          type: "item",
          url: parent + "/cadre-profile-details",
        },
        {
          id: "Engaged Cadre Details",
          title: "Engaged Cadre Details",
          type: "item",
          url: parent + "/engaged-cadre-details",
        },
      ],
    },
  ],
};

export default districtCadres;
