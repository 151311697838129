// assets
import { IconDashboard } from "@tabler/icons";

// constant
const icons = { IconDashboard };

//Menu Items
const parent = "/district";
const districtDashboard = {
    id: "districtDashboard",
    type: "group",
    children: [
        {
            id: "Dashboard",
            title: "Dashboard",
            type: "item",
            url: parent + "/dashboard",
            icon: icons.IconDashboard,
        },
    ],
};

export default districtDashboard;
