// assets
import { IconArchive } from "@tabler/icons";

// constant
const icons = {
  IconArchive,
};

//Menu Items
const parent = "/state/plf";
const statePlf = {
  type: "group",
  children: [
    {
      id: "Primary Level Federation",
      title: "Primary Level Federation",
      type: "collapse",
      icon: icons.IconArchive,

      children: [
        {
          id: "PLFs Data",
          title: "PLFs Data",
          type: "item",
          url: parent + "/plf-data-table",
        },
        {
          id: "PLF Mapping Details",
          title: "PLF Mapping Details",
          type: "item",
          url: parent + "/plf-mapping-details-table",
        },
      ],
    },
  ],
};

export default statePlf;
