// imports
import { lazy } from "react";
import Loadable from "../../../../custom-components/layout-components/Loadable";

const AddNewAnkyAnby = Loadable(lazy(() => import("../../../../views/block/thematic-intervention/farm-livelihoods/ankyAnby/AddNewAnkyAnby")));

const parent = "thematic-intervention/farm-livelihoods/"

const flAnkyAnbyRoutes = [
    { path: parent + "add-new-anky-anby", element: <AddNewAnkyAnby />, },
];

export default flAnkyAnbyRoutes;
