// assets
import { IconBooks } from "@tabler/icons";

// constant
const icons = {
  IconBooks,
};

//Menu Items
const parent = "/district/clf";
const districtClf = {
  type: "group",
  children: [
    {
      id: "Cluster Level Federation",
      title: "Cluster Level Federation",
      type: "collapse",
      icon: icons.IconBooks,

      children: [
        {
          id: "Verify New Clf",
          title: "Verify New Clf",
          type: "item",
          url: parent + "/verify-clf-table",
        },
        {
          id: "Verified CLFs Data",
          title: "Verified CLFs Data",
          type: "item",
          url: parent + "/clf-data-table",
        },
      ],
    },
  ],
};

export default districtClf;
