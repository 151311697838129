import axios from "axios";
import Cookies from 'js-cookie';
import useAuthStore from "../zustand/store";

//url
import config from "../../config";
const API_URL = `${config.Server_IP_Address}` + "api/public/users";

// route:/api/public/users/login
const login = async (userData) => {
  const response = await axios.post(API_URL + "/login", userData);
  return response.data;
};

// route:/api/public/users/checkSessionValid
const checkSessionValid = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL + `/checkSessionValid`, config);

  return response.data;
};

const userController = {
  login,
  checkSessionValid,
};

export default userController;
