// imports
import { lazy } from "react";
import Loadable from "../../custom-components/layout-components/Loadable";

//Assets

const ActivityLogsPage = Loadable(lazy(() => import("../../views/state/logs/activity-logs/ActivityLogsPage")));
const ErrorLogsPage = Loadable(lazy(() => import("../../views/state/logs/error-logs/ErrorLogsPage")));

const parent = "logs/"
const logsRoutes = [
  { path: parent + "activity-logs", element: <ActivityLogsPage />, },
  { path: parent + "error-logs", element: <ErrorLogsPage />, },
];

export default logsRoutes;
