import { Outlet } from "react-router-dom";
import { useNavigate, Navigate } from "react-router-dom";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  LinearProgress,
  Grid,
} from "@mui/material";

// project imports
import Header from "./Header";
import Sidebar from "./Sidebar";
// import { drawerWidth } from "../../store/constant";
import UserDetails from "./UserDetails";
import config from "../../config";
import userController from "../../controllers/public/userController";
import useAuthStore from "../../controllers/zustand/store";

// assets
import { useEffect, useState } from "react";
import Footer from "../../custom-components/layout-components/Footer";
import { Stack } from "@mui/system";

const drawerWidth = config.drawerWidth;
const MainLayout = () => {
  const { token } = useAuthStore();
  const theme = useTheme();
  const navigate = useNavigate();

  // Handle left drawer
  const [leftDrawerOpened, setleftDrawerOpened] = useState(true);
  const toggleDrawer = () => {
    setleftDrawerOpened(!leftDrawerOpened);
  };

  const [dataFetched, setdataFetched] = useState(false);

  //check if user is valid for further navigation
  const checkIfUserIsValid = async () => {
    setdataFetched(false)
    try {
      await userController.checkSessionValid(token)
    } catch (error) {
      navigate("/login")
    } finally {
      setdataFetched(true)
    }
  }

  useEffect(() => {
    checkIfUserIsValid()
  }, []);

  return (
    <>
      <LinearProgress color="success" />
      {dataFetched && (
        <Stack >
          <CssBaseline />
          {/* header */}
          <AppBar sx={{ zIndex: 1201 }} elevation={0} >
            <Header />
            <UserDetails
              leftDrawerOpened={leftDrawerOpened}
              toggleDrawer={toggleDrawer}
            />
          </AppBar>

          {/* drawer */}
          <Sidebar
            toggleDrawer={toggleDrawer}
            leftDrawerOpened={leftDrawerOpened}
            zIndex={1200}
          />

          {/* main content */}
          <Box sx={{ marginLeft: leftDrawerOpened ? 30 : 0 }}>
            <Box sx={{ minHeight: config.appBarHeight }}></Box>
            <Outlet />
          </Box>
          <Footer />
        </Stack>
      )}
    </>
  );
};

export default MainLayout;
