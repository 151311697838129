// imports
import { lazy } from "react";
import Loadable from "../../custom-components/layout-components/Loadable";

const PlfDataTable = Loadable(lazy(() => import("../../views/state/plf-section/plf-data/PlfDataTable")));
const PlfMappingDetailsTable = Loadable(lazy(() => import("../../views/state/plf-section/plf-mapping-details/PlfMappingDetailsTable")));

const parent = "plf/"
const statePlfRoutes = [
  { path: parent + "plf-data-table", element: <PlfDataTable />, },
  { path: parent + "plf-mapping-details-table", element: <PlfMappingDetailsTable />, },
];

export default statePlfRoutes;
