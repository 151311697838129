// assets
import { IconReportSearch } from "@tabler/icons";

// constant
const icons = {
  IconReportSearch,
};

//Menu Items
const parent = "/state/funds-tracking";
const stateFundsTracking = {
  type: "group",
  children: [
    {
      id: "Funds Tracking",
      title: "Funds Tracking",
      type: "collapse",
      icon: icons.IconReportSearch,

      children: [
        {
          id: "shgFundsTracking",
          title: "SHG Funds",
          type: "item",
          url: parent + "/shg-funds-tracking",
        },
        {
          id: "plfFundsTracking",
          title: "PLF Funds",
          type: "item",
          url: parent + "/plf-funds-tracking",
        },
        {
          id: "clfFundsTracking",
          title: "CLF Funds",
          type: "item",
          url: parent + "/clf-funds-tracking",
        },
        {
          id: "fundsDisbursement",
          title: "Funds Disbursement",
          type: "item",
          url: parent + "/funds-disbursement-table",
        },
      ],
    },
  ],
};

export default stateFundsTracking;
