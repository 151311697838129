// imports
import { lazy } from "react";
import Loadable from "../../../../custom-components/layout-components/Loadable";

const AddNewChc = Loadable(lazy(() => import("../../../../views/block/thematic-intervention/farm-livelihoods/chc/AddNewChc")));

const parent = "thematic-intervention/farm-livelihoods/"

const flChcRoutes = [
    { path: parent + "add-new-chc", element: <AddNewChc />, },
];

export default flChcRoutes;
