// assets
import { IconHeadset } from "@tabler/icons";

// constant
const icons = {
  IconHeadset,
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const parent = "/block";
const ticketing = {
  type: "group",
  children: [
    {
      id: "Ticketing",
      title: "Ticketing",
      type: "collapse",
      icon: icons.IconHeadset,

      children: [
        {
          id: "createTicket",
          title: "Create Ticket",
          type: "item",
          url: parent + "/createTicket",
        },
      ],
    },
  ],
};

export default ticketing;
