// assets
import { IconDashboard } from "@tabler/icons";

// constant
const icons = { IconDashboard };

//Menu Items
const parent = "/state";
const stateDashboard = {
    id: "stateDashboard",
    type: "group",
    children: [
        {
            id: "Dashboard",
            title: "Dashboard",
            type: "item",
            url: parent + "/dashboard",
            icon: icons.IconDashboard,
        },
    ],
};

export default stateDashboard;
