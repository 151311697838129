// imports
import { lazy } from "react";
import Loadable from "../../custom-components/layout-components/Loadable";

//Assets
const TrainingsDetailsTable = Loadable(lazy(() => import("../../views/district/trainings/TrainingDetails")));

const parent = "trainings/"
const districttrainingRoutes = [
    { path: parent + "training-details", element: <TrainingsDetailsTable />, },
];

export default districttrainingRoutes;
