// imports
import { lazy } from "react";
import Loadable from "../../custom-components/layout-components/Loadable";

//Assets
const ShgFundsTable = Loadable(lazy(() => import("../../views/state/funds-tracking/shg-funds/ShgFundsTable")));
const PlfFundsTable = Loadable(lazy(() => import("../../views/state/funds-tracking/plf-funds/PlfFundsTable")));
const ClfFundsTable = Loadable(lazy(() => import("../../views/state/funds-tracking/clf-funds/ClfFundsTable")));
const FundsDisbursementTable = Loadable(lazy(() => import("../../views/state/funds-tracking/funds-disbursement/FundsDisbursmentTable")));

const parent = "funds-tracking/"
const stateFundsTrackingRoutes = [
  { path: parent + "shg-funds-tracking", element: <ShgFundsTable />, },
  { path: parent + "plf-funds-tracking", element: <PlfFundsTable />, },
  { path: parent + "clf-funds-tracking", element: <ClfFundsTable />, },
  { path: parent + "funds-disbursement-table", element: <FundsDisbursementTable />, },
];

export default stateFundsTrackingRoutes;
