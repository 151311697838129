// imports
import { lazy } from "react";
import Loadable from "../../custom-components/layout-components/Loadable";

//Assets
const AddNewShgPage = Loadable(lazy(() => import("../../views/block/shg-section/add-new-shg/AddNewShgPage")));
const AddNewShgTable = Loadable(lazy(() => import("../../views/block/shg-section/add-new-shg/AddNewShgTable")));
const EditShgPage = Loadable(lazy(() => import("../../views/block/shg-section/add-new-shg/EditShgPage")));
const VerifiedShgDataTable = Loadable(lazy(() => import("../../views/block/shg-section/verified-shgs-data/VerifiedShgDataTable")));

const parent = "shg/"
const blockShgRoutes = [
  { path: parent + "add-new-shg", element: <AddNewShgPage />, },
  { path: parent + "add-new-shg-table", element: <AddNewShgTable />, },
  { path: parent + "edit-shg/:_id", element: <EditShgPage />, },
  { path: parent + "verified-shg-data-table", element: <VerifiedShgDataTable />, },
];

export default blockShgRoutes;
