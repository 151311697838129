// imports
import { lazy } from "react";
import Loadable from "../../../../custom-components/layout-components/Loadable";

const AddNewCvg = Loadable(lazy(() => import("../../../../views/block/thematic-intervention/farm-livelihoods/convergence/AddNewCvg")));

const parent = "thematic-intervention/farm-livelihoods/"

const flCvgRoutes = [
    { path: parent + "add-new-cvg", element: <AddNewCvg />, },
];

export default flCvgRoutes;
