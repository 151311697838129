// imports
import { lazy } from "react";
import Loadable from "../../custom-components/layout-components/Loadable";

//Assets
const ShgFundsTable = Loadable(lazy(() => import("../../views/block/funds-tracking/shg-funds/ShgFundsTable")));
const PlfFundsTable = Loadable(lazy(() => import("../../views/block/funds-tracking/plf-funds/PlfFundsTable")));
const ClfFundsTable = Loadable(lazy(() => import("../../views/block/funds-tracking/clf-funds/ClfFundsTable")));
const FundsDisbursementTable = Loadable(lazy(() => import("../../views/block/funds-tracking/funds-disbursement/FundsDisbursmentTable")));
const AddNewFundsDetailsPage = Loadable(lazy(() => import("../../views/block/funds-tracking/funds-disbursement/AddNewFundsDetailsPage")));
const EditFundsDisbursementPage = Loadable(lazy(() => import("../../views/block/funds-tracking/funds-disbursement/EditFundsDisbursementPage")));

const parent = "funds-tracking/"
const blockFundsTrackingRoutes = [
  { path: parent + "shg-funds-tracking", element: <ShgFundsTable />, },
  { path: parent + "plf-funds-tracking", element: <PlfFundsTable />, },
  { path: parent + "clf-funds-tracking", element: <ClfFundsTable />, },
  { path: parent + "funds-disbursement-table", element: <FundsDisbursementTable />, },
  { path: parent + "add-new-funds-disbursement", element: <AddNewFundsDetailsPage />, },
  { path: parent + "edit-funds-disbursement/:_id", element: <EditFundsDisbursementPage />, },
];

export default blockFundsTrackingRoutes;
