// material-ui
import { Typography } from "@mui/material";

// project imports
import NavGroup from "./NavGroup";
import menuItem from "../../../../menu-items";
import useAuthStore from "../../../../controllers/zustand/store";

const MenuList = () => {

  const { userType } = useAuthStore()
  let navItems = [];
  switch (userType) {
    case "state":
      navItems = menuItem.stateItems.map((item) => {
        switch (item.type) {
          case "group":
            return <NavGroup key={item.id} item={item} />;
          default:
            return (
              <Typography
                key={item.id}
                variant="h6"
                color="error"
                align="center"
              >
                Menu Items Error
              </Typography>
            );
        }
      });
      break;
    case "district":
      navItems = menuItem.districtItems.map((item) => {
        switch (item.type) {
          case "group":
            return <NavGroup key={item.id} item={item} />;
          default:
            return (
              <Typography
                key={item.id}
                variant="h6"
                color="error"
                align="center"
              >
                Menu Items Error
              </Typography>
            );
        }
      });
      break;
    case "block":
      navItems = menuItem.blockItems.map((item) => {
        switch (item.type) {
          case "group":
            return <NavGroup key={item.id} item={item} />;
          default:
            return (
              <Typography
                key={item.id}
                variant="h6"
                color="error"
                align="center"
              >
                Menu Items Error
              </Typography>
            );
        }
      });
      break;

    default:
      navItems = [
        <Typography variant="h6" color="error" align="center">
          Menu Items Error
        </Typography>,
      ];
      break;
  }

  return <>{navItems}</>;
};

export default MenuList;
