import { useRoutes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { lazy } from "react";
import Loadable from "../custom-components/layout-components/Loadable";

// project imports
import MainLayout from "../layout/MainLayout";

//routes imports
import blockRoutesList from "./block/BlockRoutesList";
import districtRoutesList from "./district/DistrictRoutesList";
import stateRoutesList from "./state/StateRoutesList";
import useAuthStore from "../controllers/zustand/store";
import otherRoutes from "./OtherRoutes";


//login Page
const LoginPage = Loadable(lazy(() => import("../views/authentication/LoginPage")));

const user = useAuthStore.getState().getUser();
const mainRoutes = [
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/",
    element:
      user.userType == "block" ? (
        <Navigate to="/block/dashboard" />
      ) : user.userType == "district" ? (
        <Navigate to="/district/dashboard" />
      ) : user.userType == "state" ? (
        <Navigate to="/state/dashboard" />
      ) : (
        <Navigate to="/login" />
      ),
  },
  {
    path: "/*",
    element:
      user.userType == "block" ? (
        <Navigate to="/block/dashboard" />
      ) : user.userType == "district" ? (
        <Navigate to="/district/dashboard" />
      ) : user.userType == "state" ? (
        <Navigate to="/state/dashboard" />
      ) : <Navigate to="/page-not-found" />,
  },
  {
    path: "/block/",
    element: <MainLayout />,
    children: [...blockRoutesList]
  },
  {
    path: "/district/",
    element: <MainLayout />,
    children: [...districtRoutesList],
  },
  {
    path: "/state/",
    element: <MainLayout />,
    children: [...stateRoutesList],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [...otherRoutes],
  }
];

const Routes = () => {
  return useRoutes(mainRoutes);
};

export default Routes;
