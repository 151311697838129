// imports
import { lazy } from "react";
import Loadable from "../../custom-components/layout-components/Loadable";

//basic operations
const AddNewClfTable = Loadable(lazy(() => import("../../views/block/clf-section/clf-profile/AddNewClfTable")));
const AddNewClfPage = Loadable(lazy(() => import("../../views/block/clf-section/clf-profile/AddNewClfPage")));
const EditClfPage = Loadable(lazy(() => import("../../views/block/clf-section/clf-profile/EditClfPage")));
const VerifiedClfDataTable = Loadable(lazy(() => import("../../views/block/clf-section/verified-clfs-data/VerifiedClfDataTable")));

//mapping
const ClfMappingTable = Loadable(lazy(() => import("../../views/block/clf-section/clf-mapping/ClfMappingTable")));
const PlfstoClfMapping = Loadable(lazy(() => import("../../views/block/clf-section/clf-mapping/plfs-to-clf-mapping/PlfstoClfMapping")));
const VillagesToClfMapping = Loadable(lazy(() => import("../../views/block/clf-section/clf-mapping/villages-to-clf-mapping/VillagesToClfMapping")));
const MembersToClfMapping = Loadable(lazy(() => import("../../views/block/clf-section/clf-mapping/members-to-clf-mapping/MembersToClfMapping")));

const parent = "clf/"
const blockClfRoutes = [
  { path: parent + "add-new-clf-table", element: <AddNewClfTable />, },
  { path: parent + "add-new-clf", element: <AddNewClfPage />, },
  { path: parent + "edit-clf/:_id", element: <EditClfPage />, },
  { path: parent + "clf-data-table", element: <VerifiedClfDataTable />, },

  //mapping routes
  { path: parent + "clf-mapping", element: <ClfMappingTable />, },
  { path: parent + "plfs-to-clf-mapping/:_id", element: <PlfstoClfMapping />, },
  { path: parent + "villages-to-clf-mapping/:_id", element: <VillagesToClfMapping />, },
  { path: parent + "members-to-clf-mapping/:_id", element: <MembersToClfMapping />, },

];

export default blockClfRoutes;
