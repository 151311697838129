// imports
import { lazy } from "react";
import Loadable from "../../custom-components/layout-components/Loadable";

//Assets
const VerifyPlfTable = Loadable(lazy(() => import("../../views/district/plf-section/verify-new-plf/VerifyPlfTable")));
const VerifiedPlfDataTable = Loadable(lazy(() => import("../../views/district/plf-section/verified-plfs-data/VerifiedPlfDataTable")));

const parent = "plf/"
const districtPlfRoutes = [
  { path: parent + "verify-plf-table", element: <VerifyPlfTable /> },
  { path: parent + "plf-data-table", element: <VerifiedPlfDataTable />, },
];

export default districtPlfRoutes;
