// assets
import { IconBooks } from "@tabler/icons";

// constant
const icons = {
  IconBooks,
};

//Menu Items
const parent = "/state/clf";
const stateClf = {
  type: "group",
  children: [
    {
      id: "Cluster Level Federation",
      title: "Cluster Level Federation",
      type: "collapse",
      icon: icons.IconBooks,

      children: [
        {
          id: "CLFs Data",
          title: "CLFs Data",
          type: "item",
          url: parent + "/clf-data-table",
        },
        {
          id: "CLF Mapping Details",
          title: "CLF Mapping Details",
          type: "item",
          url: parent + "/clf-mapping-details-table",
        },
      ],
    },
  ],
};

export default stateClf;
